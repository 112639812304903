import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoadingView from '@guuru/react-loading';
import { PageNotFound } from '@guuru/react-error-pages';
import useSetLanguage from './hooks/useSetLanguage';
import useSetPrintListeners from './hooks/useSetPrintListeners';
import BasicLayout from '../../containers/BasicLayout';
import Redirect from '../redirect';

const QuizDeciderView = lazy(() => import('../quiz/QuizDeciderView'));
const Pages = lazy(() => import('../pages'));
const Page = lazy(() => import('../page'));
const AnsweringChats = lazy(() => import('../answering'));
const Rewards = lazy(() => import('../rewards'));
const ManageProfile = lazy(() => import('../manage-profile'));

const MainView = function () {
  const { loading } = useSetLanguage();
  useSetPrintListeners();

  if (loading) {
    return <LoadingView />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingView />}>
        <main>
          <Routes>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/quizzes" element={<BasicLayout />}>
              <Route index element={<QuizDeciderView />} />
            </Route>
            <Route path="/:partnerId" element={<BasicLayout />}>
              <Route index element={<Pages />} />
              <Route path="experts/:expertId" element={<Redirect />} />
              <Route path="articles">
                <Route index element={<Pages />} />
                <Route path=":articleId" element={<Page />} />
              </Route>
              <Route path="answering" element={<AnsweringChats />} />
              <Route path="rewards" element={<Rewards />} />
              <Route path="manage-profile" element={<ManageProfile />} />
            </Route>
            <Route path="/:partnerId/invitation">
              <Route index element={<Redirect />} />
            </Route>
            <Route path="/:partnerId/community" element={<BasicLayout />}>
              <Route index element={<Redirect />} />
              <Route path="experts/:expertId" element={<Redirect />} />
            </Route>
          </Routes>
        </main>
      </Suspense>
    </BrowserRouter>
  );
};

export default MainView;
